html {
	font-size       : 62.5%; /* 62.5% of 16px = 10px */
	scroll-behavior : smooth;
}

body {
	margin          : 0;
	scroll-behavior : smooth;
}

//div[style*="position: absolute"][style*="z-index: 100000"][style*="pointer-events: none"][style*="color: rgba(130, 130, 130, 0.62)"][style*="text-align: center"][style*="bottom: 50%"][style*="letter-spacing: 5px"][style*="font-size: 24px"] {
//	display : none !important;
//}


.gmnoprint a, .gmnoprint span {
	display : none;
}

.gmnoprint div {
	background : none !important;
}

#GMapsID div div a div img {
	display : none;
}

